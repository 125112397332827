
import { unwrapResult } from '@reduxjs/toolkit';
import { notification } from "antd";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as Survey from "survey-react";
import "survey-react/modern.css";
import { addNewSurveyResponse, fetchSurveyResponseById } from '../../../../reduxToolkit/features/Survey/SurveyResponseSlice';
import "./Survey.scss";



Survey
    .StylesManager
    .applyTheme("modern");


export default function SurveyResponse({ match }) {

    let history = useHistory();
    const [template, setTemplate] = useState([]);
    //Define Survey JSON
    const { id } = match.params;
    const [course, setCourse] = useState()
    const [program, setProgram] = useState()
    const [state, setState] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSurveyResponseById(id))
            .then(unwrapResult)
            .then((res) => {

                res.campaign.map((campaign) => {
                    console.log('reeees', campaign)
                    if (campaign.type_category == "course") {
                        setCourse(campaign.course.title)
                        setProgram(campaign.course.program_degree_name)
                        campaign.survey_template.map(t => {

                            setTemplate(t.template);
                        })
                    }
                    else {
                        setProgram(campaign.program.program_degree_name)
                        campaign.survey_template.map(t => {

                            setTemplate(t.template);
                        })
                    }

                })
            });
    }, [id, dispatch])



    const surveyResponse = useSelector((state) => state.surveyResponse.surveyResponse)


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };




    //Define a callback methods on survey complete
    const onComplete
        = async (survey, options) => {
            //Write survey results into database
            console.log('survey.data', survey.data);
            let campaign_id = surveyResponse.campaign_id;
            let hCode = id;
            let survey_response = survey.data;
            let state = "submitted";
            console.log('survey', survey)
            const resultAction = await dispatch(

                addNewSurveyResponse({ campaign_id, survey_response, hCode, state })
            );

            if (addNewSurveyResponse.fulfilled.match(resultAction)) {
                // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk

                openNotificationWithIcon("success", `added`);
                handleClose();
                history.push("/citrine/thanks");
            } else {
                if (resultAction.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                    setHandledError(resultAction.payload);
                    handleOpenErrorModal();
                    handleClose();
                } else {
                    console.log("resultAction.error  from component", resultAction.error);
                    setHandledError(resultAction.error);
                    handleOpenErrorModal();
                    handleClose();
                }
            }
        }
    console.log('template', template)
    const model = new Survey.Model(template);
    console.log('model', model)
    return (


        <div>
            <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12} style={{ background: "#f4bb08" }}>
                    <GridContainer xs={12} sm={12} md={12} style={{ alignItems: "center" }} >
                        <GridItem xs={12} sm={12} md={5}>

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <img src={require("../../../../assets/img/logo.png")} alt="Logo" width="400px" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>

                        </GridItem>
                        {/* <GridItem xs={12} sm={4} md={6} style={{ fontSize: "1.5em", color: "#ffffff" }}><br></br><p><strong>Course : {course}</strong></p> <br></br>
                            <p><strong>Program : {program}</strong></p></GridItem>
                            <GridItem xs={12} sm={4} md={2}>
                       
                       </GridItem> */}
                    </GridContainer>
                    <GridContainer xs={12} sm={12} md={12} style={{ alignItems: "center" }} >
                        <GridItem xs={12} sm={12} md={4}>

                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} style={{ fontSize: "1.5em", color: "#ffffff", align: "center" }} >

                            {course && (<div> <p><center><strong>Course : {course}</strong></center></p></div>)}
                            <p><center><strong>Program : {program}</strong></center></p>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>

                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Survey.Survey model={model}
                        onComplete={onComplete}
                    />
                </GridItem>
            </GridContainer>

        </div>

    )
}