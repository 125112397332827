// const BaseUrl = "http://141.94.175.167:8002/api";
// export const MediaBaseUrl = "http://141.94.175.167:8002/media/";

// const BaseUrl='http://127.0.0.1:8000/api'
// export const MediaBaseUrl='http://127.0.0.1:8000/media/';
//back 141 with build adress


const BaseUrl = "https://api.citrine.university/api";
export const MediaBaseUrl = "https://api.citrine.university/media/";
export default BaseUrl;
