import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import cx from "classnames";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React from "react";
import { Switch } from "react-router-dom";
import routes from "routes.js";
import { PrivateRoute } from "../components/privateRoutes";






//console.log("currentuser from adminjs", user);
var ps;

const useStyles = makeStyles(styles);
// filtred routes applied
export default function Dashboard(props) {
  
  // const [hasImage, setHasImage] = React.useState(true);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({

    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    //console.log(logo, "logo");
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
   

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
     
    };
  });
  // functions for changeing the states from components

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  

  const getRoutes = filtredRoutes => {
    return routes.map((prop, key) => {

      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/citrine") {
        return (
          <PrivateRoute
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  

  return (
    <div className={classes.wrapper}>
      <div className={mainPanelClasses} ref={mainPanel}>
      
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                {/* <Redirect from="/admin" to="/admin/configurations" /> */}
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              {/* <Redirect from="/admin" to="/admin/configurations" /> */}
            </Switch>
          </div>
        )}
       
      </div>
    </div>
  );
}
