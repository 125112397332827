//In your react App.js or yourComponent.js file add these lines to import
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from 'react';




export default function Thanks() {
 //thanks page

    return (


        <div>
            <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12} style={{ background: "#f4bb08" }}>
                    <GridContainer xs={12} sm={12} md={12} style={{ alignItems: "center" }} >
                        <GridItem xs={12} sm={12} md={5}>

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <img src={require("../../../assets/img/logo.png")} alt="Logo" width="400px" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>

                        </GridItem>
                    </GridContainer>
                    <GridContainer xs={12} sm={12} md={12} style={{ alignItems: "center" }} >
                        <GridItem xs={12} sm={12} md={4}>

                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} style={{ fontSize: "1.5em", color: "#ffffff" }}><p><center><strong>Thank you for taking the time to complete this survey.</strong></center></p>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>

                        </GridItem>
                    </GridContainer>
                </GridItem>



            </GridContainer>

        </div>

    )
}