import { configureStore } from "@reduxjs/toolkit";
import CampaignReducer from "./features/campaign/CampaignsSlice";
import SurveyResponseReducer from "./features/Survey/SurveyResponseSlice";
import SurveyReducer from "./features/Survey/SurveySlice";
const store = configureStore({
  reducer: {
    campaign: CampaignReducer,
    surveyResponse: SurveyResponseReducer,
    survey: SurveyReducer,
  },
});

export default store;
