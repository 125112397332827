import DashboardIcon from "@material-ui/icons/Dashboard";
import SurveyResponse from "views/Pages/Surveys/StudentSurvey/SurveyResponse";
import Thanks from "views/Pages/Surveys/Thanks";


var dashRoutes = [
  {
    path: "/survey/:id",
    name: "Survey",
    rtlName: "Survey ",
    icon: DashboardIcon,
    component: SurveyResponse,
    layout: "/citrine",
  },
  {
    path: "/thanks",
    name: "Greeting",
    rtlName: "Greeting ",
    icon: DashboardIcon,
    component: Thanks,
    layout: "/citrine",
  },
];
export default dashRoutes;
