import { createSlice,  createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  campaigns: [],
  campaignsStatus: 'idle',
  campaignStatus: 'idle',
  error: null,
  campaign: null,
  campaignTemplate: null,
}

export const fetchCampaigns = createAsyncThunk('campaign/fetchCampaigns', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/campaign/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchCampaignById = createAsyncThunk('campaign/fetchCampaignById', async (id) => {
 // const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/campaign/${id}/course/`, {
    headers: {
     // 'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewCampaign = createAsyncThunk(
  'campaign/addNewCampaign',
  async (initialCampaign) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/campaign/create/`, initialCampaign, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateCampaign = createAsyncThunk(
  'campaign/updateCampaign',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/campaign/${data.id}/edit/`, data.updatedCampaign, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedCampaign')
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the Campaign: ', error)

    }
  }
)


export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/campaign/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

  return response.data
} catch (err) {
  // Use `err.response.data` as `action.payload` for a `rejected` action,
  // by explicitly returning it using the `rejectWithValue()` utility
  return rejectWithValue(err.response.data)
}
})
const campaignsSlice = createSlice({
  name: 'campaign',
  initialState,
  extraReducers: {

    [fetchCampaigns.pending]: (state, action) => {
      state.campaignsStatus = 'loading'
    },
    [fetchCampaigns.fulfilled]: (state, action) => {
      state.campaignsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.campaigns = action.payload
    },
    [fetchCampaigns.rejected]: (state, action) => {
      state.campaignsStatus = 'failed'
      state.error = action.payload
    },

    [fetchCampaignById.pending]: (state, action) => {
      state.campaignStatus = 'loading'
    },
    [fetchCampaignById.fulfilled]: (state, action) => {
      state.campaignStatus = 'succeeded'

      // Add any fetched posts to the array
      state.campaign = action.payload
    },
    [fetchCampaignById.rejected]: (state, action) => {
      state.campaignStatus = 'failed'
      state.error = action.payload
    },
    [addNewCampaign.fulfilled]: (state, action) => {
      state.campaigns.push(action.payload)
    },
    [deleteCampaign.fulfilled]: (state, action) => {
      console.log(action.payload)
    },
    [updateCampaign.fulfilled]: (state, action) => {
      const result = state.campaigns.filter(campaign => campaign.id !== action.payload.id);
      result.push(action.payload)
      state.campaigns = result
      console.log("action.payload.id from slice",action.payload)

    },
  },
})


export default campaignsSlice.reducer