import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import "./i18n";
import { Provider } from "react-redux";
import "antd/dist/antd.css";

import store from "./reduxToolkit/Store";
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="loading">
      <BrowserRouter>
        <Switch>
          <Route path="/citrine" render={(props) => <AdminLayout {...props} />} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
