import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = (props) => {
	const dispatch = useDispatch();


	return (
        <Route  path={props.path}  exact={props.exact} component={props.component} />
	       )};
